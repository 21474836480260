import React from 'react';
import ReactDOM from 'react-dom';
import MainContext from './context/MainContext'
import Routes from './Routes';
import 'antd/dist/antd.css';


ReactDOM.render(
  <MainContext>
    <Routes />
  </MainContext>
  , document.getElementById('root')
);
