import React, { useState, useEffect } from 'react'

import { Box, Button, Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'

import DataTable from 'react-data-table-component';
import moment from 'moment-timezone';


import { getAllVortexTransactionByUserIDByDaterange } from '../../../data/vortex';
import useSparkleSnackbar from '../../../../../core/useSparkleSnackbar';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { TransactionTypes } from '../../../../stores/constants/constants';
import CustomLoader from '../../../../../core/CustomLoader';



const VortexTransactionPage = (props) => {


  const [refresh, setRefresh] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [data, setdata] = useState([])

  const [renderData, setRenderData] = useState([])

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [selectedType, setSelectedType] = useState('')

  const [total, setTotal] = useState(0)


  var date = new Date();


  const [dateRangeSelection, setdateRangeSelection] = useState([
    {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59),
      key: 'selection',
    }
  ])


  const handleDaterangeSelect = (range) => {

    let newDateRange = {
      startDate: new Date(range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate()),
      endDate: new Date(range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate(), 23, 59, 59),
      key: 'selection',
    }

    setdateRangeSelection([newDateRange])

  }


  const handleGetDataRequest = () => {
    setIsLoading(true)
    setdata([])

    getAllVortexTransactionByUserIDByDaterange({ startDate: dateRangeSelection[0].startDate.toLocaleDateString(), endDate: dateRangeSelection[0].endDate, userId: props?.user?._id })

      .then((result) => {
        setdata(result)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })

  }

  const calculateTotalAmount = (data) => {
    let total = data.reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.totalAmount
    }, 0)

    setTotal(total.toFixed(2))
  }



  // HOOK COMPONENTS
  const { showSnackbar, closeSnackbar, SparkleSnackBar } = useSparkleSnackbar("bottom", "center")



  useEffect(() => {
    setRenderData(data)
  }, [data])


  useEffect(() => {
    calculateTotalAmount(renderData)
  }, [renderData])




  const columns = [
    {
      id: 'createdAt',
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
      cell: row => <span>{moment(row.createdAt
      ).tz('Asia/Dubai').format("YYYY MMMM DD - hh:mm:ss a")}
      </span>,
      grow: 2,
    },
    {
      id: "type",
      name: 'Type',
      selector: row => row.type,
    },
    {
      id: "id",
      name: 'ID',
      grow: 2,
      selector: row => (
        <Button onClick={() => {
          navigator.clipboard.writeText(row._id)
          showSnackbar("Text copied", "success")
        }}>
          {row._id}
        </Button>
      ),
    },
    {
      id: "referenceNumber",
      name: 'Ref no',
      selector: row => (
        <Button onClick={() => {
          navigator.clipboard.writeText(row.referenceNumber)
          showSnackbar("Text copied", "success")
        }}>
          {row.referenceNumber}
        </Button>
      ),
    },
    {
      id: "store",
      name: 'Store',
      selector: row => row.store.name,
      sortable: true,
    },
    {
      id: "status",
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      id: "oneAedToPhp",
      name: '1 AED TO PHP',
      selector: row => row.oneAedToPhp,
      cell: row => (
        <span>{
          `${row.oneAedToPhp} PHP`
        }
        </span>),
      sortable: true,
    },
    {
      id: "convenienceFee",
      name: 'Conv Fee',
      selector: row => row.convenienceFee,
      cell: row => (
        <span>{
          `${row.convenienceFee} AED`
        }
        </span>),
      sortable: true,
    },
    {
      id: "amount",
      name: 'Amount',
      selector: row => row.totalAmount,
      cell: row => (
        <span>{
          `${row.totalAmount} AED`
        }
        </span>),
      sortable: true,
    },


  ];




  return (
    <Box>
      <Box m={2}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Stack direction={"row"} spacing={2}>
                <Stack>
                  {/* Date range picker */}
                  <DateRangePicker
                    // onChange={item => setdateRangeSelection([item.selection])}
                    onChange={item => handleDaterangeSelect(item.selection)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRangeSelection}
                    direction="horizontal"
                  />
                  <Box>
                    <Button variant={"contained"} onClick={() => {
                      handleGetDataRequest()
                    }}>GET DATA</Button>
                  </Box>
                </Stack>
                {/* Filters */}
                {
                  data.length > 0 &&
                  <Box>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {"Select type"}
                        </InputLabel>
                        <Select
                          defaultValue={'all'}
                          label={"Select type"}
                          placeholder={"Select type"}
                          value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e.target.value)
                            if (data.length > 0) {
                              setResetPaginationToggle(false)

                              if (e.target.value === 'all') {
                                setRenderData(data)
                              }

                              let filterData = data.filter(
                                transaction => transaction?.type.includes(e.target.value)
                              )

                              setRenderData(filterData)
                            }
                          }}
                        >
                          <MenuItem value={"all"}>
                            <em>All</em>
                          </MenuItem>
                          {
                            TransactionTypes.map(types => {
                              return <MenuItem value={types.split(":")[1]}>{types.split(":")[0]}</MenuItem>
                            })
                          }
                        </Select>
                      </FormControl>
                      <TextField label="Transaction ID"
                        onChange={(e) => {
                          if (data.length > 0) {
                            setResetPaginationToggle(false)

                            let filterData = data.filter(
                              transaction => transaction?._id.includes(e.target.value)
                            )

                            setRenderData(filterData)
                          }

                        }} />
                      <TextField label="Reference no"
                        onChange={(e) => {
                          if (data.length > 0) {
                            setResetPaginationToggle(false)

                            let filterData = data.filter(
                              transaction => transaction?.referenceNumber.includes(e.target.value)
                            )

                            setRenderData(filterData)
                          }

                        }} />

                    </Stack>
                  </Box>
                }
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <DataTable
                pagination
                columns={columns}
                data={renderData}
                defaultSortFieldId={'createdAt'}
                defaultSortAsc={false}
                progressPending={isLoading}
                paginationResetDefaultPage={resetPaginationToggle}
                progressComponent={<CustomLoader />}
              />
              {/* Total amount */}
              <Box>
                <Stack direction={"row"} justifyContent={"center"}>
                  <Box textAlign={"center"}>
                    <Typography fontWeight={"Bold"} sx={{ color: "gray" }}>TOTAL AMOUNT</Typography>
                    <Typography fontWeight={"Bold"} fontSize={70}>{String(total).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                    <Typography fontWeight={"Bold"} sx={{ color: "gray" }}>AED</Typography>
                  </Box>
                </Stack>
              </Box>
              {/* Download CSV */}
              <Box>
                <Button variant='contained'
                  onClick={() => {
                    if (renderData.length > 0) {
                      const csvString = [
                        ["Created at", "Type", "ID", "Ref No", "Cashier", "Store", "Status", "1 AED TO PHP", "ConvFee", "Total Amount"],
                        ...renderData.map(item => [
                          item.createdAt,
                          item.type,
                          item._id,
                          item.referenceNumber,
                          item.userId.name,
                          item.store.name,
                          item.status,
                          item.oneAedToPhp,
                          item.convenienceFee,
                          item.totalAmount
                        ])
                      ].map(e => e.join(","))
                        .join("\n");

                      let csvContent = "data:text/csv;charset=utf-8,"
                        + csvString

                      var today = new Date();

                      var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

                      var encodedUri = encodeURI(csvContent);
                      var link = document.createElement("a");
                      link.setAttribute("href", encodedUri);
                      link.setAttribute("download", `sandbox_vortex_transactions_${date}.csv`);
                      document.body.appendChild(link); // Required for FF

                      link.click();
                    }

                  }}
                >Download CSV</Button>
              </Box>
            </CardContent>

          </Card>

        </Stack>

      </Box>
      <SparkleSnackBar />
    </Box>
  )



}

export default VortexTransactionPage

