import React, { useState, useEffect } from 'react'

import { AppBar, Box, Button, Card, CardContent, CircularProgress, Container, IconButton, InputBase, Stack, Switch, TextField, Toolbar, Typography } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RefreshIcon from '@mui/icons-material/Refresh';

import { useHistory } from "react-router-dom";

import { Form, Formik } from 'formik';
import { getUserByRole, searchCashiers } from './data/users';
import UserViewer from './dialogs/userviewer/UserViewer'
import useAddNewCashier from './hook-components/useAddNewCashier';
import useUpdateCashier from './hook-components/useUpdateCashier'
import StatusChip from './component/StatusChip'
import DataTable from 'react-data-table-component';


import moment from 'moment-timezone';
import CustomLoader from '../../core/CustomLoader';


const UserDirectoryMainPage = () => {

  const history = useHistory();

  const [refresh, setrefresh] = useState(false)

  const [data, setdata] = useState([])

  const [renderData, setRenderData] = useState([])

  const { showUserViewerDialog, closeUserViewerDialog, UserViewerDialog } = UserViewer()

  const { showAddNewCashier, closeAddNewCashier, AddNewCashier } = useAddNewCashier()

  const { showUpdateCashier, closeUpdateCashier, UpdateCashier } = useUpdateCashier()

  const [isLoading, setIsLoading] = useState(true)

  const handleCashierSearch = async ({ name = "" }) => {
    try {
      setIsLoading(true)
      let result = await searchCashiers(name)
      setRenderData(result)
      setIsLoading(false)
    } catch (error) {
      throw error
    }
  }


  const getAllCashiers = async () => {
    try {
      let result = await getUserByRole({ roleNumber: 0 })
      setdata(result)
      setIsLoading(false)
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    getAllCashiers()
  }, [refresh])

  useEffect(() => {
    setRenderData(data)
  }, [data])


  const columns = [
    {
      id: 'status',
      name: 'Status',
      sortable: true,
      selector: row => (
        <StatusChip status={row?.status} />
      ),
    },
    {
      id: 'createdAt',
      name: 'Created At',
      selector: row => row?.createdAt,
      sortable: true,
      cell: row => <span>{moment(row?.createdAt
      ).tz('Asia/Manila').format("YYYY MMMM DD - hh:mm:ss a")}
      </span>,
    },
    {
      id: 'name',
      name: 'Cashier name',
      selector: row => row?.name,
    },
    {
      id: 'email',
      name: 'Email',
      selector: row => row?.email,
      grow: 2,
    },
    {
      id: 'store',
      name: 'Store',
      sortable: true,
      selector: row => row?.store?.name,
      grow: 2,
    },
    {
      id: 'actions',
      name: 'Action',
      selector: row => (
        <Button onClick={() => {
          showUserViewerDialog(row)
        }}>View</Button>
      ),
    },
    {
      id: 'update',
      name: 'Update',
      selector: row => (
        <Button onClick={() => {
          showUpdateCashier(row)
        }}>Update</Button>
      ),
    },
  ];








  return (
    <Box>
      <AppBar>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() => {
              history.goBack();
            }}>
            <ChevronLeftIcon />
          </IconButton>
          <Box >
            <Formik initialValues={{}} onSubmit={async (data) => { handleCashierSearch({ name: data?.searchvalue }) }}>
              {({ handleChange, isSubmitting }) => {
                return <Form>
                  <Stack spacing={2} direction={"row"}>
                    <Box sx={{
                      marginLeft: 0,
                      width: '100%',
                      borderRadius: "0.2em",
                      backgroundColor: "#ffffff15",
                      '&:hover': {
                        backgroundColor: "#ffffff25",
                      },

                    }}>
                      <InputBase
                        sx={{
                          padding: "5px",
                          color: "white"
                        }}
                        disabled={isSubmitting}
                        name={"searchvalue"}
                        onChange={handleChange}
                        placeholder={"Search cashier name..."}
                        required
                      />
                      <IconButton type="submit" disabled={isSubmitting}>
                        {isSubmitting ? <CircularProgress size={30} /> : <SearchIcon sx={{ fill: "white" }} />}
                      </IconButton>

                    </Box>

                  </Stack>

                </Form>
              }}
            </Formik>
          </Box>
          <Box ml={1}>
            <Button variant='contained' onClick={() => {
              showAddNewCashier()
            }}>
              ADD CASHIER
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Button
                startIcon={<RefreshIcon />}
                onClick={() => {
                  setrefresh(!refresh)
                }}>CLEAR FILTERS</Button>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <DataTable
                defaultSortAsc={false}
                columns={columns}
                data={renderData}
                pagination
                defaultSortFieldId={'createdAt'}
                progressComponent={<CustomLoader />}
                progressPending={isLoading}
              />
            </CardContent>
          </Card>
        </Stack>
      </Box>
      <UserViewerDialog />
      <AddNewCashier onSuccess={() => {
        setrefresh(!refresh)
      }} />
      <UpdateCashier onSuccess={() => {
        setrefresh(!refresh)
      }} />
    </Box>
  )
}

export default UserDirectoryMainPage