import React, { useState } from "react";
import Layout from "../core/Layout";
import { Redirect } from 'react-router-dom';
import { signin, authenticate, isAuthenticated } from '../auth';
import { Formik, Form } from 'formik'

import { Link } from "react-router-dom";
import spark from '../assets/spark.png';
import { Button, Card, CardContent, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";

import CollapseAlert from "../core/CollapseAlert"

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Signin = () => {


    const [values, setValues] = useState({
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });

    const { email, password, loading, error, redirectToReferrer } = values;

    const { user } = isAuthenticated();

    const [alertState, setAlertState] = useState({
        open: false,
        severity: "success",
        message: "Message"
    })

    const [showPassword, setShowPassword] = useState(false)

    const signInForm = () => (
        <Formik
            initialValues={{}}
            onSubmit={async (data) => {

                try {

                    let result = await signin({ email: data?.email, password: data?.password })

                    console.log(result)

                    if (result.user.role !== 99) {
                        setValues({ ...values, error: "For admin accounts only", loading: false });
                    } else {
                        authenticate(result, () => {
                            setValues({
                                ...values,
                                redirectToReferrer: true
                            });
                        })

                    }

                } catch (error) {
                    setAlertState((prevState) => ({ ...prevState, open: true, severity: "error", message: JSON.parse(error.message).error }))
                }

            }}
        >
            {
                ({ handleChange, isSubmitting }) => {
                    return (
                        <Form>
                            <Stack spacing={2} textAlign={"center"}>
                                <Stack direction={"row"} justifyContent={"center"}>
                                    <img
                                        className="img-fluid bayadirect"
                                        src="https://sparkle-vortex.imgix.net/bayadirect.jpg?w=300&h=300"
                                        alt="company-logo"
                                        style={{
                                            objectFit: "contain"
                                        }} />
                                </Stack>
                                <Typography fontWeight={"bold"} fontSize={25}>Log in</Typography>
                                <Typography fontSize={14} sx={{ color: "gray" }}>Sign in on the internal platform</Typography>
                                <TextField
                                    required
                                    name={"email"}
                                    type={"email"}
                                    label={"Email Address"}
                                    placeholder={"Enter email address"}
                                    onChange={handleChange}
                                />
                                <TextField
                                    required
                                    name={"password"}
                                    type={showPassword ? "text" : "password"}
                                    label={"Password"}
                                    placeholder={"Enter password"}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityIcon />
                                                ) : (
                                                    <VisibilityOffIcon />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <CollapseAlert
                                    open={alertState.open}
                                    severity={alertState.severity}
                                    message={alertState.message}
                                    onClose={() => {
                                        setAlertState((prevState) => ({ ...prevState, open: false }))
                                    }}
                                />
                                <Divider />
                                <Button disabled={isSubmitting} variant={"contained"} type={"submit"} sx={{ height: 50 }}>{isSubmitting ? "Please wait..." : "SIGN IN"}</Button>

                            </Stack>
                        </Form>
                    )
                }
            }
        </Formik>
    );

    const redirectUser = () => {
        if (redirectToReferrer) {
            if (user && user.role > 0) {
                return <Redirect to='/admin/dashboard' />
            } else {
                return <Redirect to='/user/dashboard' />
            }
        }
        if (isAuthenticated()) {
            return <Redirect to='/' />
        }
    }

    return (
        <Layout>
            <div className="container">
                <div className="hv-100 d-flex align-items-center justify-content-center login__container">
                    <div className="w-50 card_login-content">
                        <h2 className="text-center">Welcome to  <br /> Sandbox <br />BayaDirect!</h2>
                        <img src={spark} className="img-fluid spark_img" alt="sparkles" draggable="false" />
                    </div>
                    <div className="w-50 h-50 m-50 d-flex align-items-center justify-content-center">
                        <Card elevation={10} sx={{ minWidth: 300, maxWidth: 500, width: 500, marginTop: 20, paddingBottom: 5 }}>
                            <CardContent sx={{ padding: 5 }}>
                                {signInForm()}
                                {redirectUser()}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>

            {/*This following JSON allows us to see the current object as string*/}
            {/*JSON.stringify(values)*/}
        </Layout>
    );
};

export default Signin;



