import * as React from 'react';
import { Box, Typography } from '@mui/material';
import AllStores from './pages/AllStores/AllStores';
import StoreEnvPage from './pages/StoreEnv/StoreEnvPage';






export default function Content(props) {

  console.log('contentIndex', props.contentId)


  const RenderPage = ({ contentId = 0 }) => {



    switch (contentId) {
      case 0:
        return <AllStores />
      case 1:
        return <StoreEnvPage />
      default:
        return <Box>
          <Typography>No page setup</Typography>
        </Box>
    }
  }



  return (
    <RenderPage contentId={props.contentId} />
  );
}
